// App.js
import {useState, useContext} from 'react';
import './App.css';
import {FirebaseContext} from './FirebaseContext'; // Import Firebase context

function App() {
    const [text, setText] = useState('');
    const {username, addDocument, messages} = useContext(FirebaseContext); // Use context


    return (
        <div className="App">
            <header className="App-header">
                <div>
                    {messages.map(doc => (
                        <p id='Msg' key={doc.id}>{doc.username}: {doc.text}</p>
                    ))}
                </div>
                <div className='bottom'>
                    <input type="text" value={text} onChange={(e) => setText(e.target.value)} id='boxMsg'/>
                    <button onClick={() => addDocument(text, username)} id='sendMsg'>Send</button>
                </div>
            </header>
        </div>
    );
}

export default App;
