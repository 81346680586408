// FirebaseContext.js
import React, {createContext, useState, useEffect} from 'react';
import db from './firebase';
import {collection, onSnapshot} from 'firebase/firestore';
import Cookies from 'js-cookie';
import {addDoc, query, orderBy, limit} from 'firebase/firestore';
import {logger} from './logger';

export const FirebaseContext = createContext();

export const FirebaseProvider = ({children}) => {
    const [username, setUsername] = useState('');
    const [messages, setMessages] = useState([]);

    // Handle username setup and Firestore listener here
    useEffect(() => {
        if (Cookies.get("CF_Authorization")) {
            logger.info("Cookie exists")

            const cookie = Cookies.get("CF_Authorization");
            logger.info(cookie)

            let name = cookie.split("@")[0]
            logger.info(name)

            setUsername(name);
        } else {
            while (!Cookies.get("CF_Authorization")) {
                console.log("Cookie does not exist")
                let name = prompt("Please enter your email: ");
                if (name === null || name === "" || !name.includes("@")) {
                    logger.warning("This name ",name," is invalid");
                    alert("invalid email please retry");
                }
                else{
                    Cookies.set("CF_Authorization", name);
                    logger.info("Cookie set")
                    window.location.reload();
                }
            }
        }

        const q = query(collection(db, collection_name()), orderBy('time', 'desc'), limit(10));
        const unsubscribe = onSnapshot(q, (snapshot) => {
            setMessages(
                snapshot.docs
                    .map(doc => ({id: doc.id, ...doc.data()}))
                    .sort((a, b) => a.time - b.time));
        });

        return () => unsubscribe();
    }, []);


    const collection_name = () => {
        if (process.env.REACT_APP_ENV === 'preprod') {
            return "message_from_writer_preprod";
        } else {
            return "message_from_writer";
        }
    };
    const addDocument = async (text) => {
        try {
            const time = new Date().getTime()
            await addDoc(collection(db, collection_name()), {username, text, time});
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    };

    return (
        <FirebaseContext.Provider value={{username, addDocument, messages}}>
            {children}
        </FirebaseContext.Provider>
    );
};
