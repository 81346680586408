// logger.js
const log = (level, message) => {
    console[level](message);
};

const logger_helper = () => {
    if (process.env.REACT_APP_ENV === 'preprod') {
        return logger_preprod;
    } else {
        return logger_prod;
    }
};

const logger_preprod = {
    debug: (message) => log('log', message),
    info: (message) => log('info', message),
    warning: (message) => log('warn', message),
    error: (message) => log('error', message),
};

const logger_prod = {
    debug: (message) => {},
    info: (message) => {},
    warning: (message) =>{} ,
    error: (message) => {},
};

export const logger = logger_helper();
// Usage
/*
import { logger } from './logger';

logger.debug('Debug message');
logger.info('Info message');
logger.warn('Warning message');
logger.error('Error message');
*/
