// firebase.js
import {getFirestore} from "firebase/firestore";
import {initializeApp} from "firebase/app";

const firebaseConfig_prod = {
    apiKey: "AIzaSyAGOjY-Cug1jiNcs36YCfhelFDY8bd-TaE",
    authDomain: "eceylan-1a.firebaseapp.com",
    projectId: "eceylan-1a",
    storageBucket: "eceylan-1a.appspot.com",
    messagingSenderId: "463334332769",
    appId: "1:463334332769:web:723f2dddcee0ff10848d20",
    measurementId: "G-9BG305BS6F"
};
const firebaseConfig_preprod = {
    apiKey: "AIzaSyAGOjY-Cug1jiNcs36YCfhelFDY8bd-TaE",
    authDomain: "eceylan-1a.firebaseapp.com",
    projectId: "eceylan-1a",
    storageBucket: "eceylan-1a.appspot.com",
    messagingSenderId: "463334332769",
    appId: "1:463334332769:web:45f64cdb2082fa53848d20",
    measurementId: "G-JS4ECEX1CB"
};
const firebaseConfi_helper = () => {
    if (process.env.REACT_APP_ENV === 'preprod') {
        return firebaseConfig_preprod;
    } else {
        return firebaseConfig_prod;
    }
};

const firebaseConfig = firebaseConfi_helper();

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default db
